.content {
    overflow-y: auto !important;
    max-height: calc(var(--vh, 1vh) * 100 - var(--needHeight, 95px));
    min-height: calc(var(--vh, 1vh) * 100 - var(--needHeight, 95px));
    padding: 1rem;
}

.badge {
    font-weight: 600;
    line-height: 1.4;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 600;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}

.badge:hover {
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
}