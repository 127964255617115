.transactions-box{
    width: 100%;
}

.transactions-box .transaction-item{
    width: 100%;
    height: 70px;
    background-color: rgb(255, 255, 255);
    justify-content: space-between;
    align-items: center;
    display: flex;
    border-radius: 10px;
    margin-bottom: 5px;
    padding: 0 15px 0 15px;
    border: 1px solid #060b26;
}

.transactions-box .transaction-item .transaction-percent{
    display: flex;
    align-items: center;
    justify-content: right;
}
.transactions-box .transaction-item .transaction-percent svg{
    font-size: 18px;
}

.transactions-box .transaction-item .transaction-kwh{
    display: flex;
    align-items: center;
    justify-content: right;
}
.transactions-box .transaction-item .transaction-kwh svg{
    font-size: 18px;
}
