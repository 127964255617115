.modal-swipe{
    margin: 0 !important;
    max-width: none !important;
    transition: bottom 0.2s ease-in !important;
}
.modal-swipe .modal-header{
    border-bottom: none;
    justify-content: center;
    flex-wrap: wrap;
}
.modal-swipe .modal-header .modal-stick{
    height: 5px;
    width: 50%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    margin-bottom: 10px;
}
.modal-swipe .modal-header .modal-title{
    width: 100%;
    text-align: center;
}
.modal-swipe .modal-content{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.modal-swipe .modal-content .modal-body{
    padding: 0;
}
.modal-swipe .modal-content .swiper-slide{
    display: flex;
    justify-content: center;
    align-content: center;
}
.modal-swipe .modal-content .swiper-slide-active .swiper-slide-value{
    flex-wrap: wrap;
    display: flex;
    width: 60%;
    background: lightgray;
    padding: 10px 20px;
    border-radius: 10px;
    justify-content: center;
    align-content: center;
}
.modal-swipe .modal-content .swiper-slide .swiper-slide-value{
    flex-wrap: wrap;
    display: flex;
    width: 60%;
    padding: 10px 20px;
    border-radius: 10px;
    justify-content: center;
    align-content: center;
}
.modal-swipe .modal-content .swiper-slide .swiper-slide-value.unavaible{
    color: #6c757d;
}
.modal-swipe .modal-footer{
    border-top: none;
    justify-content: center;
}