.widget{
    padding: 15px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    color: #515365;
    background: #fff;
    box-shadow: 0 0 8px rgba(122,122,122,0.8);
    -webkit-box-shadow:0 0 8px rgba(122,122,122,0.8);
    -moz-box-shadow:0 0 8px rgba(122,122,122,0.8);
}