  @media (max-width:900px) {
    .sidebar-menu {
      background-color: #060b26;
      width: 100%;
      height: calc(100vh - 60px);
      display: block;
      position: absolute;
      margin: 0 0 0 -100%;
      transition: 250ms;
      z-index: 9998;
    }

    .sidebar-menu.active {
      margin: 0 0 0 0;
      transition: 250ms;
    }
  }

  @media (min-width:900px) {
    .sidebar-menu {
      background-color: #060b26;
      width: 270px;
      height: calc(100vh - 60px);
      display: block;
      float: left;
      margin: 0 0 0 -100%;
      transition: 650ms;
    }

    .sidebar-menu.active {
      margin: 0 0 0 0;
      transition: 250ms;
    }
  }

  .sidebar-text {
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 8px;
    list-style: none;
    height: 60px;
    color: #f5f5f5;
    font-size: 18px;
  }

  .sidebar-text.active a {
    background-color: #1a83ff;
    color: #f5f5f5;
  }

  .sidebar-text.active div {
    background-color: #1a83ff;
    color: #f5f5f5;
  }

  .sidebar-text span {
    margin-left: 8px;
    margin-right: 8px;
  }

  .sidebar-text div {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    cursor: pointer;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
  }

  .sidebar-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }

  .sidebar-text a:hover {
    background-color: #1a83ff;
    color: #f5f5f5;
  }

  .sidebar-text div:hover {
    background-color: #1a83ff;
    color: #f5f5f5;
  }

  .sidebar-menu-items {
    width: 100%;
    padding: 0;
  }