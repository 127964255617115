.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #060b26;
    height: 60px;
}

.menu .back{
    background: none;
    border: none;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 15px;
    color: aliceblue;
    text-decoration: none;
    border-radius: 10px;
    max-width: 85px;
}

.menu-item.active {
    color: #2fc57e;
}

.menu-item svg {
    font-size: 30px;
}

.menu-item.active svg {
    color: #2fc57e !important;
}

.menu-item-count{
    position: absolute;
    top: 3px;
    right: 15px;
}

.menu-item.active .menu-item-count{
    color: #2fc57e;
}
.cards-logos{
    height: 30px;
    margin-bottom: 5px;
}