.profile{

}
.profile .image{
    font-size: 50vw;
}

.profile .fio{
    font-size: 20px;
}

.profile .accountNumber{
    margin-left: 5px;
    font-size: 16px;
    padding: 5px;
    background-color: lightgrey;
    border-radius: 5px;
}

.profile .btn{
    min-width: 50vw;
}