@media (max-width:900px) {
  .content.admin {
    overflow-y: auto !important;
    max-height: calc(var(--vh, 1vh) * 100 - var(--needHeight, 60px));
    min-height: calc(var(--vh, 1vh) * 100 - var(--needHeight, 60px));
    padding: 1rem;
  }
}

@media (min-width:900px) {
  .content.admin {
    overflow-y: auto !important;
    max-height: calc(100vh - var(--needHeight, 60px));
    margin-left: 0px;
    padding: 1rem;
    transition: 450ms;
  }

  .content.admin.sidebar-active {
    margin-left: 200px;
    transition: 250ms;
  }
}

.badge {
  font-weight: 600;
  line-height: 1.4;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 600;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

.badge:hover {
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}