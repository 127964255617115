.navbar-right-position {
    justify-content: end !important;
}

.navbar {
    background-color: #060b26;
    height: 60px;
}

.navbar-burger {
    margin-left: 1.5rem;
    font-size: 1.5rem;
    background: none;
}

.nav-item {
    color: aliceblue;
    list-style-type: none;
}

.dropdown-menu[data-bs-popper] {
    margin-top: 0 !important;
}

.dropdown-menu.show {
    right: 0 !important;
    left: auto !important;
    z-index: 9999;
}

.dropdown-toggle::after {
    border: 0 !important;
}