.charging-box{
    justify-content: center;
    align-content: center;
}

.charging-box .btn-zap{
    font-size: 32px !important;
    padding: 4px 12px;
    width: auto;
    border-radius: 50%;
}

.charging-box .btn-zap svg{
    margin-bottom: 8px;
}