.modal-dialog-right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    width: 100%;
    max-width: 600px;
}
.modal-dialog-right .modal-content {
    min-height: -webkit-fill-available;
    border-radius: 0;
    border: 0;
}
.modal-dialog-right .modal-content .modal-body {
    position: absolute;
    top: 63px;
    bottom: 63px;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
}
.modal-dialog-right .modal-content .modal-footer {
    position: absolute;
    width: inherit;
    bottom: 0;
    border-top: 1px solid #dee2e6;
    padding: 0 0.75rem;
    background-color: white;
}